import { HttpClient, HttpHeaders } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { map } from 'rxjs';

import { EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';
import { ProductApiSettings } from '@ppg/core/models';

import { Category, Product, CategoryRequestBody, ProductsRequestBody, MediaAssetRequest } from '../models';

/**
 * @warning Temporary normalization solution for problems with PIM described in ticket ASM: 11578197
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeToPascalCase = (data: any): any => {
  function toPascalCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  if (Array.isArray(data)) {
    return data.map(normalizeToPascalCase);
  } else if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce(
      (acc, key) => {
        const value = data[key];
        const propertyName = toPascalCase(key);
        const propertyValue = normalizeToPascalCase(value);
        acc[propertyName] = propertyValue;
        return acc;
      },
      {} as Record<string, unknown>,
    );
  }
  return data;
};

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  static readonly apiKeyHeaderName = 'X-Api-Key';
  static readonly subscriptionKeyHeaderName = 'Subscription-Key';

  readonly #http = inject(HttpClient);
  readonly #environmentService = inject(EnvironmentService);

  // TODO: Getters and Header signal will be replaced with configuration values as these the values doesn't change over time
  get #baseUrl() {
    return `${this.#environmentService.getEnvironmentVariable(EnvVarsNames.APIM_PRODUCT_API_BASE_URL)}`;
  }

  readonly #httpHeaders = computed(() => {
    const apiKey = this.#environmentService.getEnvironmentVariable(EnvVarsNames.APIM_PRODUCT_API_API_KEY) ?? '';
    const subscriptionKey =
      this.#environmentService.getEnvironmentVariable(EnvVarsNames.APIM_PRODUCT_API_SUBSCRIPTION_KEY) ?? '';
    return new HttpHeaders({
      [ProductApiService.apiKeyHeaderName]: apiKey,
      [ProductApiService.subscriptionKeyHeaderName]: subscriptionKey,
    });
  });

  /**
   * @retrns 200 - AssortmentResponse
   * @returns 401 - Request didn’t pass the authorization
   * @returns 404 - Products with specified params weren't found
   * @returns 500 - Internal server error
   */
  getAssortment(assortmentId: string | number, body: ProductsRequestBody, odataQuery: string) {
    const query = this.normalizeQuery(odataQuery);
    return this.#http.post<Product[]>(`${this.#baseUrl}assortment/${assortmentId}/products${query}`, body, {
      headers: this.#httpHeaders(),
      transferCache: true,
    });
  }

  /**
   * @returns 200 - AssortmentResponse
   * @returns 401 - Request didn’t pass the authorization
   * @returns 404 - Products with specified params weren't found
   * @returns 500 - Internal server error
   */
  getCrossSellProducts(
    assortmentId: string | number,
    body: ProductsRequestBody,
    odataQuery: string,
    productNumbers: string[] = [],
  ) {
    const query = this.normalizeQuery(odataQuery);
    return this.#http.post<Product[]>(`${this.#baseUrl}assortment/${assortmentId}/products${query}`, body, {
      headers: this.#httpHeaders(),
      params: {
        $filter: `${this.createFilterByProductNumbers(productNumbers)}`,
      },
      transferCache: true,
    });
  }

  /**
   * @warning This method uses data normalization function to fix problems inconsistant property casing
   */
  getAllAssortment(assortmentId: string | number, body: ProductsRequestBody) {
    return this.#http
      .post(`${this.#baseUrl}assortment/${assortmentId}/products`, body, {
        headers: this.#httpHeaders(),
        transferCache: true,
      })
      .pipe(map((data) => normalizeToPascalCase(data) as Product[]));
  }

  createAssortmentQuery(settings: ProductApiSettings, media: MediaAssetRequest[] = []): ProductsRequestBody {
    return {
      channel: settings.channel,
      webHierarchy: settings.webHierarchy,
      languages: settings.languageCode,
      salesOrganization: settings.salesOrganization,
      media,
    };
  }

  createCategoriesQuery(settings: ProductApiSettings, languageOverride?: string): CategoryRequestBody {
    return {
      channel: settings.channel,
      languages: [languageOverride ?? settings.languageCode[0]],
      salesOrganization: settings.salesOrganization,
      media: [],
    };
  }

  /**
   * @param webhierarchyId - PIM Root/Node Category Id to filter out Leaf categories.
   *
   * @returns 200 - CategoryResponse
   * @returns 401 - Request didn’t pass the authorization
   * @returns 404 - Products with specified params weren't found
   * @returns 500 - Internal server error
   */
  getCategories(webhierarchyId: string, body: CategoryRequestBody, odataQuery: string) {
    const query = this.normalizeQuery(odataQuery);
    return this.#http.post<Category[]>(`${this.#baseUrl}webhierarchy/${webhierarchyId}/categories${query}`, body, {
      headers: this.#httpHeaders(),
      transferCache: true,
    });
  }

  /**
   * @warning This method uses data normalization function to fix problems inconsistant property casing
   */
  getAllCategories(webhierarchyId: string, body: CategoryRequestBody) {
    return this.#http
      .post(`${this.#baseUrl}webhierarchy/${webhierarchyId}/categories`, body, {
        headers: this.#httpHeaders(),
        transferCache: true,
      })
      .pipe(map((data) => normalizeToPascalCase(data) as Category[]));
  }

  /**
   * odata2ts query builder creates quieries
   * @param odataQuery - odata2ts builder strings containing ie. categories?$top=10
   * @param odataQuery - manually created query strings containing ie. $top=10
   * @param odataQuery - undefined - returns an empty string
   */
  normalizeQuery(odataQuery: string): string {
    return `?${odataQuery.replace(/^(categories\?|products\?)/, '')}`;
  }

  createFilterByProductNumbers(productsNumbers: string[]): string | null {
    if (!productsNumbers?.length) {
      return null;
    }

    return `ProductNumber in (${productsNumbers.map((num) => `'${num}'`).join(',')})`;
  }
}
